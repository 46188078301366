<template>
  <suspense>
    <router-view/>
  </suspense>
 
</template>

<style lang="less">
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html{
    width: 100%;
    height: 100%;
  }
  body{
    width: 100%;
    height: 100%;
    background-image: linear-gradient(135deg, rgba(115, 248, 224, 1), rgba(150, 167, 241, 1));
  }
</style>
