import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/:area?',
    name: 'enter',
    component: () => import('../views/EnterView.vue')
  },
  {
    path: '/:area/:roomName',
    name: 'room',
    component: () => import('../views/RoomView.vue')
  } 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
